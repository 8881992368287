
lightbox.option({
  'hasVideo': true,
  'disableScrolling': true,
});


$(document).ready(function() {
  $('[data-lightbox]').on('click', function () {
    $('html').css('overflow-y', 'hidden');

    if ($(window).innerWidth() <= 1023) {
      lightbox.option({
        'positionFromTop': 50,
      });
    } else {
      lightbox.option({
        'positionFromTop': $(window).innerHeight() / 7,
      });
    }
  })


  $('#lightboxOverlay').on('click', function () {
    $('html').css('overflow-y', 'visible')
  });

  $('#lightbox').on('click', function () {
    $('html').css('overflow-y', 'visible')
  });

  $('.lb-close').on('click', function () {
    $('html').css('overflow-y', 'visible')
  })




});

$(function videos() {

  var videos = $('.videos--slider');

  function setSlick(e) {
    e.slick({
      mobileFirst: true,
      slidesToShow: 1,
      arrows: true,
      autoplay: false,
      duration: 2000,
      responsive: [{
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      }]
    });
  }

  setSlick(videos)

  $(window).on('resize', function() {
    if ($(window).innerWidth() <= 500) {
      videos.slick('unslick');
    } else {
      setSlick(videos);
    }
  })
})


